import { type Logger, RollbarLogger } from "@fxwrapr/util";
import * as Sentry from "@sentry/astro";
import Rollbar from "rollbar";

const rollbar =
  import.meta.env.PUBLIC_DEPLOY_ENV === "preview" ||
  import.meta.env.PUBLIC_DEPLOY_ENV === "production"
    ? Rollbar.init({
        accessToken: import.meta.env.PUBLIC_ROLLBAR_TOKEN,
        payload: { environment: import.meta.env.PUBLIC_DEPLOY_ENV },
        captureUncaught: true,
        captureUnhandledRejections: true,
      })
    : undefined;

export const logger: Logger = new RollbarLogger("info", rollbar);

Sentry.init({
  dsn: import.meta.env.SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: import.meta.env.PUBLIC_DEPLOY_ENV,
});
