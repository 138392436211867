const levels = {
  critical: 0,
  error: 1,
  warn: 2,
  info: 3,
  debug: 4,
  trace: 5
};
class RollbarLogger {
  constructor(level = "trace", rollbar) {
    this.level = level;
    this.rollbar = rollbar;
  }
  critical(...args) {
    this.rollbar?.critical(...args);
    if (levels[this.level] < levels["critical"]) return;
    console.error(...args);
  }
  error(...args) {
    this.rollbar?.error(...args);
    if (levels[this.level] < levels["error"]) return;
    console.error(...args);
  }
  warn(...args) {
    this.rollbar?.warn(...args);
    if (levels[this.level] < levels["warn"]) return;
    console.warn(...args);
  }
  info(...args) {
    this.rollbar?.info(...args);
    if (levels[this.level] < levels["info"]) return;
    console.info(...args);
  }
  log(...args) {
    this.rollbar?.log(...args);
    if (levels[this.level] < levels["info"]) return;
    console.log(...args);
  }
  debug(...args) {
    this.rollbar?.debug(...args);
    if (levels[this.level] < levels["debug"]) return;
    console.debug(...args);
  }
  trace(...args) {
    this.rollbar?.debug(...args);
    if (levels[this.level] < levels["trace"]) return;
    console.trace(...args);
  }
}
class Timer {
  timestamp = performance.now();
  reset = () => this.timestamp = performance.now();
  raw = () => performance.now() - this.timestamp;
  ms = (digits = 3) => this.raw().toFixed(digits);
  s = (digits = 3) => (this.raw() / 1e3).toFixed(digits);
}
export {
  RollbarLogger,
  Timer
};
